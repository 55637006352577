import React, { useState } from "react";
import { NavItem } from "react-bootstrap";

function Members() {

    const [isRun, setIsRun] = useState(false);
    
    //parameterized
    let url='https://pwf3vjhvv1.execute-api.us-east-1.amazonaws.com/items'; //API endpoint URL
    //let url='https://sb3bh4o4ji.execute-api.us-east-1.amazonaws.com/items'; //API endpoint URL
    //let url='https://learnwebcode.github.io/json-example/animals-1.json';
    //let url2='https://learnwebcode.github.io/json-example/animals-2.json';
    //let url3='https://learnwebcode.github.io/json-example/animals-3.json';
    let loc0='#mapAug';
    let loc1='#mapJuly';
    let loc2='#mapJune';
    let loc3='#mapMay';

    //e for URL data, d for location tag value
    const handleClick = (e,d) => { //function handleClick(e,d) {}
        let html = '<div></div>';
        let jsonURL;
        if (isRun) { 
          setIsRun(false);
          console.log(html);
          document.querySelector(d).innerHTML= html;
          return; 
        } else {
          setIsRun(true);
          fetch(e)
          .then(response => response.json())
          .then(data => {
            console.log(data);

            html = data.Items.map(user => {
              return '<div>'+ user.fname + ' ' + user.NTRP + ' ' + user.home_court + '</div>'; 
              //return '<div>Name: ' + '' + '</div>';
            }).join("");
            console.log(html);
            //document.querySelector('#mapJuly').innerHTML= html;
            document.querySelector(d).innerHTML= html;
        })
        }
    }

    return (
            <div>
              <p>2021 Sept Open - registration <button onClick={()=>handleClick(url,loc0)}>show/hide</button></p>
              <div id="mapAug"></div>
              <p>2021 August Open - players <button onClick={()=>handleClick(url,loc0)}>show/hide</button></p>
              <div id="mapAug"></div>
              <p>2021 July Open <button onClick={()=>handleClick(url,loc1)}>result</button></p>
              <div id="mapJuly"></div>
              <p>2021 June Open <button onClick={()=>handleClick(url,loc2)}>result</button></p>
              <div id="mapJune"></div>     
              <p>2021 May Open <button onClick={()=>handleClick(url,loc3)}>result</button></p>
              <div id="mapMay"></div>          
            </div>
      );
    
}

export default Members;