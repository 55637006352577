import React, { useState } from "react";
import ReactDOM from "react-dom";
import '../App.css';

function Rules() {

    const [isRun, setIsRun] = useState(false);
    
    //parameterized
    let url='https://learnwebcode.github.io/json-example/animals-1.json';
    let url2='https://learnwebcode.github.io/json-example/animals-2.json';
    let url3='https://learnwebcode.github.io/json-example/animals-3.json';
    let loc='#mapJuly';
    let loc2='#mapJune';
    let loc3='#mapMay';

    //e for URL data, d for location tag value
    const handleClick = (d) => { //function handleClick(e,d) {}
        let html = '<div></div>';
        let jsonURL;
        if (isRun) { 
          setIsRun(false);
          console.log(html);
          //document.querySelector('#mapJuly').innerHTML= html;
          document.querySelector(d).innerHTML= html;
          return; 
        } else {
            setIsRun(true);
            html = '<ul  style="mystyle">'
            +'<li>"You will play in the tournament format of 8 single players!"</li>'
            +'<li>"If you win the 1st round, you move on to the next/semi-final, then final."</li>'
            +'<li>"If you lose the 1st round, you move on to the consolation draw and play."</li>'
            +'<li>"You will play three single matches regardless of win or lose."</li>'
            +'<li>"You must complete the 1st match in the 1st week and the 2nd match in the 2nd week of the month."</li>'
            +'<li>"The 3rd match must be completed in the last two weeks of the month."</li>'
            +'</ul>';
            console.log(html);
            //document.querySelector('#mapJuly').innerHTML= html;
            document.querySelector(d).innerHTML= html;
            return;
        }
    }

    return (
        <div>
            <p>Play format and Rules <button onClick={()=>handleClick('#rule')}>show/hide</button></p>
              <div className="mystyle" id="rule"></div>
        </div>
      );
    
}

export default Rules;