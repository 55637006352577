
import React from "react";

function Players() {

    const getPlayers=() => {
        console.log("hi I am Toshio");
        alert("Hi I am Toshio!");
    };

    return (
        <div>
            {/*<h3>This is the Players component</h3> }
            <button onClick = {getPlayers}>Players!</button> */}
        </div>
    );
}

export default Players;